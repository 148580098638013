.App {
  text-align: center;

  .title {
    font-size: 24px;
    padding-top: 1rem;
  }

  .disclaimer {
    font-size: 11px;
  }
}
